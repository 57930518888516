import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
import "style/leaflet.css";
import { LocaleContext } from "context/locale-context";
import LocalizedLink from "components/localizedLink";
import { isNull } from "utils/helper";

function ItemPopupContent({ item, type, linkFrom }) {
  const locale = useContext(LocaleContext);
  const isAra = locale === "ar";

  let itemLink = null;
  let linkState = null;
  let title_attr = isAra ? "title_ar" : "title_en";
  let date_attr = "date_of_incident";
  let perps, date;
  if (type === "observations") {
    linkState = {
      modal: true,
      from: linkFrom,
    };
    itemLink = `database/unit/${item.observation_id}`;
  } else if (type === "incidents") {
    itemLink = `data/incidents/${item.incident_id}`;
    if (item.poss_perps != null) {
      perps = (
        <span className="MapPopupAttribute">
          <b>Possible perpetrators:</b> {item.poss_perps}
        </span>
      );
    }
  }
  if (item[date_attr] != null) {
    date = (
      <span className="MapPopupAttribute">
        <b>Date:</b> {item[date_attr]}
      </span>
    );
  }

  return (
    <span>
      <LocalizedLink to={itemLink} state={linkState}>
        {item[title_attr]}
      </LocalizedLink>

      {date}
      {perps}
    </span>
  );
}
function SudanMap({
  items,
  type,
  hoveredItemID,
  linkFrom,
  zoom,
  lat,
  lng,
  fullwidth,
}) {
  // NOTE: `items` can be either observations or incidents
  const [isMapReady, setIsMapReady] = useState(false);

  useEffect(() => {
    // Handle CSS load sync issue (https://github.com/PaulLeCam/react-leaflet/issues/156)
    setIsMapReady(true);
  }, []);

  const markerColor = (item) => {
    return hoveredItemID === itemId(item) ? "#008080" : "#E95351";
  };

  const itemId = (item) => {
    if (type === "incidents") return item.incident_id;
    else if (type === "observations") return item.observation_id;
  };

  if (typeof window !== "undefined") {
    let cls = "leaflet-map";
    if (fullwidth) cls += " fw";
    return (
      <>
        {!isMapReady ? (
          <div>Loading</div>
        ) : (
          <MapContainer center={[lat, lng]} zoom={zoom} className={cls}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {isNull(items) ||
              items.map((item) => {
                if (item.latitude === null || item.longitude === null)
                  return null;
                return (
                  <CircleMarker
                    key={itemId(item)}
                    radius={15}
                    color={markerColor(item)}
                    center={[item.latitude, item.longitude]}
                  >
                    <Popup>
                      <ItemPopupContent
                        item={item}
                        type={type}
                        linkFrom={linkFrom}
                      />
                    </Popup>
                  </CircleMarker>
                );
              })}
          </MapContainer>
        )}
      </>
    );
  }
  return null;
}

SudanMap.propTypes = {
  items: PropTypes.array,
  hoveredItemID: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  type: PropTypes.string,
  linkFrom: PropTypes.string,
  fullwidth: PropTypes.bool,
};

SudanMap.defaultProps = {
  lat: 15.608584,
  lng: 32.5421577,
  zoom: 14,
  type: "observations",
  linkFrom: null,
  fullwidth: false,
};

export default SudanMap;
