import React, { useContext } from "react";
import PropTypes from "prop-types";
import useTranslations from "hooks/useTranslations";
import { ThemeContext } from "context/theme-context";

// Mapping of dataset -> array of tag names to show
const TAG_LOOKUP = {
    "coupfiles": [
      {title: "Possible perpetrators", attribute: "poss_perps"},
      {title: "Indicators of a peaceful protest", attribute: "ind_peace_prot"},
      {title: "Indicators of disproportionate use of force", attribute: "ind_disp_force"},
      {title: "Indicators of SGBV", attribute: "ind_sgbv"},
      {title: "Indicators of unlawful use of teargas/crowd control tactics", attribute: "ind_tear"},
      {title: "Indicators of targeted killings", attribute: "ind_kill"},
      {title: "Indicators of extrajudicial killings of detainees", attribute: "ind_extrjud"},
    ],
    "june-security": [
      {title: "Casualties", attribute: "Casualties"},
      {title: "Collection", attribute: "collection_[LOC]"},
      {title: "Confidence level", attribute: "confidence"},
      {title: "Neighbourhood", attribute: "neighbourhood_[LOC]"},
      {title: "Possible perpetrators", attribute: "Possible_perpetrator_1"},
      {title: "Possible perpetrators (cont'd)", attribute: "Possible_perpetrator_2"},
    ]
  }


function Tag({ title, info }) {
    const tr = useTranslations();
    const { colors } = useContext(ThemeContext);
    return (
        <div css={{ marginBottom: "0.3rem" }}>
        <h5 css={{ color: colors.primary, marginTop: "1.5rem" }}>{title}:</h5>
        <p>{tr(info)}</p>
        </div>
    );
}

function TagsDisplay({data, locale, dataset}) {
    const showTags = TAG_LOOKUP[dataset] || [];
    const tr = useTranslations();
    return showTags.map((tag) => {
          let attr = tag.attribute.replace("[LOC]", locale);
          return <Tag key={attr} title={tr(tag.title)} info={data[attr]} />
    });
}

TagsDisplay.propTypes = {
    data: PropTypes.object,
    locale: PropTypes.string,
    dataset: PropTypes.string
};

export default TagsDisplay;
