import { useStaticQuery, graphql } from "gatsby";

export default function useObservations(obs_id_arr) {
  if (!obs_id_arr) return ({ observations: [] })
  // TODO: Should this run an actual query on observation.id in obs_id_arr (filtering is inefficient)
  const data = useStaticQuery(graphql`
    query obs {
      allUnitsJson {
        edges {
          node {
            observation_id
            title_en
            title_ar
            longitude
            latitude
          }
        }
      }
    }
  `);
  const allObservations = data.allUnitsJson.edges.map((node) => node.node);

  const observations = allObservations.filter((node) => obs_id_arr.indexOf(node.observation_id) > -1);
  return { observations };
}
